<template>
    <a-drawer
        :title="edit ? 'Редактировать событие' : 'Добавить событие'"
        placement="right"
        class="event_form_drawer"
        :width="drawerWidth"
        :class="isMobile && 'mobile'"
        :visible="visible"
        :zIndex="zIndex"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_body" ref="eventAddBody">
            <a-form-model
                ref="eventForm"
                :model="form"
                :rules="rules">
                <div class="lg:flex lg:items-start">
                    <a-form-model-item ref="name" label="Название" prop="name" class="w-full">
                        <a-input v-model="form.name" size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="event_type" label="Тип события" prop="event_type" class="lg:ml-5">
                        <a-select v-model="form.event_type" size="large" :loading="eventTypeLoader" :getPopupContainer="getPopupContainer" class="event_type_select">
                            <a-select-option v-for="item in eventTypeList" :key="item.id" :value="item.code">
                                {{ item.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <a-form-model-item ref="description" label="Описание события" prop="description">
                    <component
                        :is="ckEditor"
                        :key="edit || visible"
                        v-model="form.description" />
                </a-form-model-item>
                <div class="lg:grid grid-cols-2 gap-5 mb-5">
                    <a-form-model-item ref="start_at" label="Дата начала" prop="start_at" class="mb-0">
                        <div class="flex items-center">
                            <a-date-picker 
                                v-model="form.start_at" 
                                :allowClear="false" 
                                size="large" 
                                placeholder="Выбрать дату" 
                                format="DD.MM.YYYY"
                                :getCalendarContainer="getPopupContainer"
                                :locale="locale"
                                class="w-full"
                                :showToday="false"
                                @change="changeStartAt">
                            </a-date-picker>
                            <a-time-picker 
                                v-if="!form.all_day"
                                v-model="form.start_at" 
                                :allowClear="false" 
                                format="HH:mm" 
                                :getPopupContainer="getPopupContainer"
                                :minuteStep="5"
                                size="large" 
                                class="ml-2 time_picker" 
                                placeholder="Время"
                                @change="changeStartAtTime" />
                        </div>
                    </a-form-model-item>
                    <a-form-model-item ref="end_at" label="Дата завершения" prop="end_at" class="mb-0">
                        <div class="flex items-center">
                            <a-date-picker 
                                v-model="form.end_at" 
                                :allowClear="false" 
                                format="DD.MM.YYYY"
                                :locale="locale"
                                size="large" 
                                :getCalendarContainer="getPopupContainer"
                                placeholder="Выбрать дату" 
                                class="w-full"
                                :showToday="false"
                                @change="changeEndAt" />
                            <a-time-picker 
                                v-if="!form.all_day"
                                v-model="form.end_at" 
                                :allowClear="false" 
                                size="large" 
                                :getPopupContainer="getPopupContainer"
                                :minuteStep="5"
                                format="HH:mm" 
                                class="ml-2 time_picker" 
                                placeholder="Время"
                                @change="changeEndAtTime" />
                        </div>
                    </a-form-model-item>
                    <div class="mt-3 lg:mt-0">
                        <a-checkbox :checked="form.all_day" @change="allDayChange">
                            Весь день
                        </a-checkbox>
                    </div>
                </div>
                <a-form-model-item ref="members" label="Участники" prop="members">
                    <!-- <UserDrawer
                        v-model="form.members"
                        inputSize="large"
                        multiple
                        class="w-full"
                        title="Выбрать участников" /> -->

                    <UserDrawer
                        id="calendar"
                        :metadata="{ key: 'members', value: form.metadata }"
                        :changeMetadata="changeMetadata"

                        v-model="form.members"
                        multiple
                        inputSize="large"
                        title="Выбрать участников" />

                </a-form-model-item>
                <div class="lg:grid grid-cols-2 gap-5">
                    <a-form-model-item class="w-full" ref="address" label="Место проведения" prop="address">
                        <a-input v-model="form.address" size="large" />
                    </a-form-model-item>
                    <a-form-model-item class="w-full" ref="calendar" label="Календарь" prop="calendar">
                        <div v-if="form.related_object" class="flex items-center ant-input ant-input-lg cursor-default truncate">
                            <a-badge v-if="form.related_object.color" :color="form.related_object.color" /> <span class="truncate">{{ form.related_object.name }}</span>
                        </div>
                        <a-select 
                            v-else
                            v-model="form.calendar" 
                            size="large" 
                            :getPopupContainer="getPopupContainer"
                            :loading="calendarLoading" 
                            class="w-full c_select"
                            @popupScroll="getDataScrollHandler">
                            <a-select-option v-for="item in calendarList" :key="item.id" :value="item.id" :title="item.name" class="truncate">
                                <div class="flex items-center truncate"><a-badge :color="item.color" /> {{ item.name }}</div>
                            </a-select-option>

                            <template slot="dropdownRender" slot-scope="menu">
                                <v-nodes :vnodes="menu" />
                                <div v-if="calendarLoading" slot="notFoundContent" class="flex justify-center p-2">
                                    <a-spin size="small" />
                                </div>
                            </template>
                        </a-select>
                    </a-form-model-item>
                </div>
                <a-form-model-item ref="notify_min" label="Напомнить за" prop="notify_min">
                    <div class="flex items-center">
                        <a-input-number 
                            v-model="form.notify_min" 
                            size="large" 
                            :min="1" 
                            style="min-width: 200px;"
                            :parser="minParser"
                            :max="10000" />
                        <span class="ml-4">мин.</span>
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="color" label="Цвет события" prop="color">
                    <ColorPicker v-model="form.color" typeList />
                </a-form-model-item>
                <div class="lg:grid grid-cols-2 gap-5">
                    <a-form-model-item class="w-full" ref="calprivacyendar" label="Настройки приватности" prop="privacy">
                        <a-select v-model="form.privacy" size="large" :getPopupContainer="getPopupContainer" :loading="privacyLoading" class="w-full">
                            <a-select-option v-for="item in privacyList" :key="item.id" :value="item.code">
                                {{ item.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <a-form-model-item ref="meeting_url" label="Ссылка на конференцию" prop="meeting_url">
                    <a-input v-model="form.meeting_url" size="large" />
                </a-form-model-item>
                <a-form-model-item
                    label="Файлы"
                    prop="attachments">
                    <FileAttach
                        ref="fileAttach"
                        :attachmentFiles="form.attachments"
                        class="mt-2">
                        <template v-slot:openButton>
                            <a-button 
                                flaticon
                                icon="fi-rr-download">
                                Загрузить файл
                            </a-button>
                        </template>
                    </FileAttach>
                </a-form-model-item>
            </a-form-model>
        </div>
        <div class="drawer_footer">
            <a-button 
                type="primary" 
                :loading="loading"
                :block="isMobile"
                :size="isMobile ? 'large' : 'default'"
                @click="onSubmit()">
                Сохранить
            </a-button>
        </div>
    </a-drawer>
</template>

<script>
import ColorPicker from './ColorPicker.vue'
import UserDrawer from '@apps/DrawerSelect/index.vue'
import FileAttach from '@apps/vue2Files/components/FileAttach'
import eventBus from '@/utils/eventBus'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import Vue from 'vue'
export default {
    components: {
        ColorPicker,
        FileAttach,
        UserDrawer,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 800)
                return 800
            else {
                return '100%'
            }
        },
        ckEditor() {
            if(this.visible)
                return () => import('@apps/CKEditor')
            else
                return null
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            locale,
            visible: false,
            edit: false,
            loading: false,
            page: 1,
            uKey: 'default',
            next: true,
            eventTypeList: [],
            eventTypeLoader: false,
            calendarList: [],
            calendarLoading: false,
            privacyList: [],
            privacyLoading: false,
            selectedDate: false,
            zIndex: 1000,
            rules: {
                name: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' },
                    { max: 255, message: 'Максимум 255 символов', trigger: 'blur' }
                ],
                calendar: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                event_type: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                end_at: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ],
                start_at: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ]
            },
            form: {
                metadata: {
                    members: []
                },
                address: '',
                attachments: [],
                calendar: null,
                color: '',
                description: '',
                end_at: null,
                event_type: null,
                meeting_url: '',
                members: [],
                name: '',
                notify_at: null,
                notify_min: 30,
                privacy: null,
                start_at: null,
                all_day: false
            }
        }
    },
    methods: {
        changeMetadata({key, value}) {
            Vue.set(this.form.metadata, key, value)
        },
        getPopupContainer() {
            return this.$refs.eventAddBody
        },
        minParser(value) {
            value = value.replace(/[^0-9.,]/g, "")
            value = value.replace(',', '.')
            if(Number(value) < 1)
                value = 1
            return Number(value).toFixed(0)
        },
        onSubmit() {
            this.$refs.eventForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = JSON.parse(JSON.stringify(this.form))

                        if(formData.members.length) {
                            formData.members = formData.members.map(us => {
                                return us.id
                            })
                        }
                        if(formData.attachments.length) {
                            formData.attachments = formData.attachments.map(fl => {
                                return fl.id
                            })
                        }
                        if(formData.notify_min) {
                            formData.notify_at = this.$moment(formData.start_at).add(-formData.notify_min, 'minutes')
                        }
                        if(formData.start_at) {
                            formData.start_at = this.$moment(formData.start_at).toISOString()
                        }
                        if(formData.end_at) {
                            formData.end_at = this.$moment(formData.end_at).toISOString()
                        }
                        if(this.edit) {
                            const { data } = await this.$http.put(`/calendars/events/${formData.id}/`, formData)
                            if(data) {
                                this.$message.info('Событие успешно обновлено')
                                this.visible = false
                                const { id } = formData
                                let query = Object.assign({}, this.$route.query)
                                if(query.event && Number(query.event) !== id || !query.event) {
                                    query.event = id
                                    this.$router.push({query})
                                }
                                eventBus.$emit('edit_event', data)
                                eventBus.$emit('header_event_update', data)
                            }
                        } else {
                            const { data } = await this.$http.post('/calendars/events/', formData)
                            if(data) {
                                this.$message.info('Событие успешно создано')
                                eventBus.$emit(`add_event_${this.uKey}`, data)
                                eventBus.$emit('header_event_update', data)
                                this.visible = false
                            }
                        }
                    } catch(e) {
                        console.log(e)
                        this.$message.error('Ошибка')
                    } finally {
                        this.loading = false
                    }
                } else {
                    console.log('error submit!!');
                    if(this.isMobile) {
                        this.$message.warning('Заполните обязательные поля')
                    }
                    return false;
                }
            });
        },
        afterVisibleChange(vis) {
            if(vis) {
                if(!this.form.related_object)
                    this.getCalendarList()
                
                this.getEventTypes()
                this.getEventPrivacy()

                if(!this.edit && !this.selectedDate) {
                    this.form.start_at = this.$moment()
                    this.form.end_at = this.$moment().add(1, 'hours')
                    this.selectedDate = false
                }
            } else {
                if(this.edit) {
                    const { id } = this.form
                    let query = Object.assign({}, this.$route.query)
                    if(query.event && Number(query.event) !== id || !query.event) {
                        query.event = id
                        this.$router.push({query})
                    }
                }

                this.page = 1
                this.next = true
                this.eventTypeList = []
                this.selectedDate = false
                this.calendarList = []
                this.privacyList = []
                this.edit = false
                this.uKey = 'default'
                this.form = {
                    address: '',
                    attachments: [],
                    calendar: null,
                    color: '',
                    description: '',
                    end_at: null,
                    event_type: null,
                    meeting_url: '',
                    members: [],
                    name: '',
                    notify_at: null,
                    notify_min: 30,
                    privacy: null,
                    start_at: null,
                    all_day: false
                }
                this.zIndex = 1000
            }
        },
        allDayChange(e) {
            this.form.all_day = e.target.checked
            if(e.target.checked) {
                this.form.start_at = this.$moment(this.form.start_at).set('hour', 0).set('minute', 0).set('second', 0)
                this.form.end_at = this.$moment(this.form.end_at).set('hour', 23).set('minute', 59).set('second', 59)
            } else {
                this.form.start_at = this.$moment(this.form.start_at).set('hour', this.$moment().format('HH')).set('minute', this.$moment().format('mm')).set('second', this.$moment().format('ss'))
                this.form.end_at = this.$moment(this.form.end_at).set('hour', this.$moment().add(1, 'hours').format('HH')).set('minute', this.$moment().format('mm')).set('second', this.$moment().format('ss'))
            }
        },
        changeStartAtTime(e) {
            const eDate = JSON.parse(JSON.stringify(e))
            this.form.end_at = this.$moment(eDate).add(1, 'hours')
        },
        changeEndAtTime(e) {
            const eDate = JSON.parse(JSON.stringify(e))
            if(this.$moment(eDate).isBefore(this.form.start_at)) {
                this.form.start_at = this.$moment(eDate).add(-1, 'hours')
            }
            if(this.$moment(eDate).format('HH:mm') === this.$moment(this.form.start_at).format('HH:mm')) {
                this.form.start_at = this.$moment(eDate).add(-10, 'minutes')
            }
        },
        changeStartAt(e) {
            const eDate = JSON.parse(JSON.stringify(e))
            this.form.end_at = this.$moment(eDate).add(1, 'hours')
        },
        changeEndAt(e) {
            const eDate = JSON.parse(JSON.stringify(e))
            if(this.$moment(eDate).isSameOrBefore(this.form.start_at)) {
                this.form.start_at = this.$moment(eDate).add(-1, 'hours')
            }
        },
        async getEventPrivacy() {
            try {
                this.privacyLoading = true
                const { data } = await this.$http.get('/app_info/select_list/', {
                    params: {
                        model: 'event_calendar.EventCalendarPrivacyModel'
                    }
                })
                if(data) {
                    this.privacyList = data.selectList
                    if(!this.form.privacy) {
                        this.form.privacy = data.selectList[0].code
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.privacyLoading = false
            }
        },
        async getEventTypes() {
            try {
                this.eventTypeLoader = true
                const { data } = await this.$http.get('/app_info/select_list/', {
                    params: {
                        model: 'event_calendar.EventCalendarTypeModel'
                    }
                })
                if(data) {
                    this.eventTypeList = data.selectList
                    if(!this.form.event_type) {
                        this.form.event_type = data.selectList[0].code
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.eventTypeLoader = false
            }
        },
        async getDataScrollHandler(event) {
            if(this.next && !this.calendarLoading) {
                let target = event.target
                if(target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    try {
                        this.calendarLoading = true
                        this.page += 1
                        const { data } = await this.$http.get('/calendars/', {
                            params: {
                                page_size: 15,
                                page: this.page
                            }
                        })
                        if(data) {
                            this.calendarList = this.calendarList.concat(data.results)
                        }
                        if(!data.next) {
                            this.next = false
                        }
                    } catch(e) {
                        console.log(e)
                        this.next = false
                    } finally {
                        this.calendarLoading = false
                    }
                }
            }
        },
        async getCalendarList() {
            try {
                this.calendarLoading = true
                const { data } = await this.$http.get('/calendars/', {
                    params: {
                        page_size: 15,
                        page: this.page
                    }
                })
                if(data) {
                    this.calendarList = data.results
                    if(!this.form.calendar) {
                        this.form.calendar = data.results[0].id
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.calendarLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on('open_event_form', (startDate = null, endDate = null, event = null, related_object = null, uKey = 'default') => {
            this.uKey = uKey
            if(this.$route.query.viewProject || this.$route.query.task || this.$route.query.viewGroup) {
                this.zIndex = 1050
            }
            if(event) {
                this.edit = true
                this.form = {
                    ...event,
                    end_at: this.$moment(event.end_at),
                    start_at: this.$moment(event.start_at),
                    event_type: event.event_type?.code || null,
                    privacy: event.privacy?.code || null,
                    calendar: event.calendar?.id || null
                }
                if(event.notify_at) {
                    this.form.notify_min = this.$moment(event.start_at).diff(event.notify_at, 'minutes')
                }
                if(event.privacy) {
                    this.privacyList = [{...event.privacy}]
                }
                if(event.event_type) {
                    this.eventTypeList = [{...event.event_type}]
                }
                if(event.calendar?.related_object) {
                    this.form.related_object = event.calendar
                    this.form.calendar = event.calendar.id
                }

                if(this.form.all_day) {
                    const end = this.$moment(event.end_at).add(-1, 'days')
                    this.form.start_at = this.$moment(event.start_at).set('hour', 0).set('minute', 0).set('second', 0).set('second', 0)
                    this.form.end_at = this.$moment(end).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59)
                } else {
                    this.form.start_at = this.$moment(event.start_at)
                    this.form.end_at = this.$moment(event.end_at)
                }
            } else {
                if(startDate) {
                    if(this.$moment(startDate, 'YYYY-MM-DD', true).isValid()) {
                        if(this.$moment(endDate).diff(startDate, 'days') > 1) {
                            const end = this.$moment(endDate).add(-1, 'days')
                            this.form.start_at = this.$moment(startDate).set('hour', 0).set('minute', 0).set('second', 0).set('second', 0)
                            this.form.end_at = this.$moment(end).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59)
                        } else {
                            this.form.start_at = this.$moment(startDate).set('hour', 0).set('minute', 0).set('second', 0).set('second', 0)
                            this.form.end_at = this.$moment(startDate).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59)
                        }
                        this.form.all_day = true
                    } else {
                        this.form.start_at = this.$moment(startDate)
                        this.form.end_at = this.$moment(endDate)
                    }
                    this.selectedDate = true
                }
                if(related_object) {
                    this.form.related_object = related_object
                    this.form.calendar = related_object.id
                }
            }
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('open_event_form')
    }
}
</script>

<style lang="scss" scoped>
.event_form_drawer{
    .time_picker{
        min-width: 120px;
    }
    .event_type_select{
        min-width: 170px;
        &::v-deep{
            .ant-select-selection__rendered{
                margin-right: 34px;
            }
        }  
    }
    &::v-deep{
        .ant-drawer-content{
            overflow: hidden;
            padding: 0px;
        }
        .ant-drawer-header{
            padding-left: 20px;
            padding-right: 20px;
        }
        .ant-drawer-body{
            height: calc(100% - 40px);
            padding: 0px;
        }
    }
    .drawer_body{
        height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px;
        .c_select{
            &::v-deep{
                .ant-select-selection-selected-value{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .drawer_footer{
        display: flex;
        align-items: center;
        height: 40px;
        border-top: 1px solid var(--border2);
        padding-left: 20px;
        padding-right: 20px;
    }
    &.mobile{
        &::v-deep{
            .drawer_body{
                height: calc(100% - 50px);
            }
            .drawer_footer{
                height: 50px;
            }
        }
    }
}
</style>